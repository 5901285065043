<template>
<div>
   <v-app-bar
    id="app-bar"
    absolute
    app
    color="#e0e0e044"
    outlined
    flat
    height="75" style="margin-left: 260px;"
  >
    <a @click="$router.push({ name: 'Home' })" style="margin-right: 30px;">
      <img src="@/assets/logo/Logo-Morado.svg" height="25px" />
    </a>
    <navbar-responsive-menu />
    
  </v-app-bar>
  <menu-vertical></menu-vertical>
</div>
 
</template>

<script>
// Components

import NavbarResponsiveMenu from "@/components/mylogy/NavbarResponsiveMenu.vue";
// Utilities

import { getMyGenotype } from "@/models/GenotypeModel.js";
import { getMyPGXGenes } from "@/models/PGXGenesModel.js";
import appbase from "@/services/appbase.js";
import TileButton from "@/components/mylogy/TileButton.vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import MenuVertical from "@/components/mylogy/MenuVertical.vue";

export default {
  name: "DashboardCoreAppBar",

  components: {
    NavbarResponsiveMenu,
    MenuVertical
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({}),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("pgx", ["getDrugResult"])
  },
  mounted() {
    let self = this;

    getMyGenotype().then(res => {
      self.setGenotype(res);
      getMyPGXGenes().then(res => {
        self.setPGXGenes(res);
        self
          .loadAllDrugs()
          .then(function(response) {
            self.setPGXDrugResults(response.hits.hits);
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    });
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    ...mapActions("pgx", ["setGenotype", "setPGXGenes", "setPGXDrugResults"]),
    loadAllDrugs() {
      return appbase.drugs.search({
        body: {
          size: 2000,
          query: {
            bool: {
              must: [
                {
                  term: {
                    "card.keyword": "y"
                  }
                }
              ]
            }
          },
          _source: [
            "es",
            "po",
            "en",
            "atc_2_EN",
            "atc_2_PO",
            "atc_2_ES",
            "substrate_of"
          ]
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#app-bar {
  backdrop-filter: blur(40px);
}
</style>
